import { Center } from '@chakra-ui/react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import root from 'window-or-global';

import track from '../../../common/analytics/track';
import { Columns, Row } from '../../../common/components/Grid';
import formFieldsHandler from '../../../common/decorators/FormFieldsHandler';
import CartHeader from '../../../dashboard/cart/components/CartHeader';
import { Modes } from '../../../dashboard/cart/config';
import { handleTogglePaymentLinkPopup } from '../../../public/smart-links/actions/SmartLinksActions';
import { fetchReportAbuseInfo, fetchTosPoliciesInfo } from '../actions/SettingsActions';
import {
  handleToggleOpenState,
  handleTogglePaymentPopup,
  setOfferPagePaymentButtonClickListener,
  showDeferredMerchantHeader,
} from '../actions/SettingsActions';
import paymentWrapper from '../decorators/PaymentWrapper';
import { getPaymentsState } from '../reducers';
import { getCartTotalItems, inIframe, offerData } from '../utils/GlobalUtils';
import LoginNeft from './finalize/LoginNeft';
import PaymentCompletionLoader from './finalize/PaymentCompletionLoader';
import InstapayForm from './instapay/InstapayForm';
import InstapayMerchantHeader from './instapay/InstapayMerchantHeader';
import LinksMerchantHeader from './links/LinksMerchantHeader';
import BrandFooter from './payments/BrandFooter';
import PaymentContent from './payments/PaymentContent';
import PaymentHeader from './payments/PaymentHeader';
import PaymentUnavailable from './payments/PaymentUnavailable';
import PostPurchase from './PostPurchase';
import ProductsMerchantHeader from './products/ProductsMerchantHeader';
class Payments extends React.Component {
  componentDidMount() {
    if (this.props.settings.seller?.username) {
      const { isCustomDomain, baseURL } = offerData();
      this.props.fetchReportAbuseInfo(this.props.settings.seller?.username, isCustomDomain, baseURL);
      this.props.fetchTosPoliciesInfo(this.props.settings.seller?.username, isCustomDomain, baseURL);
    }
    // the app is ready - broadcast to anyone listening.
    root.parent.postMessage('onRequestShow', '*');
    if (!this.props.settings.isPaymentResponse) {
      //Setting the offerButtonclick listener if popup is not ready and its not a smart-link payment. (Smart link apps handeled)
      if (!root.isPaymentsPopupReady && !this.props.settings.isLinkPayment) {
        this.props.setOfferPagePaymentButtonClickListener();
      }
      this.props.showDeferredMerchantHeader();
      track();
    }
  }

  handleKeyDown(event) {
    if (event.keyCode === 27 /*esc*/) {
      event.preventDefault();
      parent.postMessage('onRequestClose', '*');
    }
  }

  isMediumDevice = () => {
    return root.innerWidth > 780 && root.innerWidth < 992;
  };

  _renderPaymentForm() {
    const {
      settings: {
        isShowRefinePurchaseBox,
        isShowFinalizePurchaseBox,
        isInitializeFormOpen,
        isRefineFormOpen,
        isFinalizeFormOpen,
        isOfferAvailable,
        isRetrying,
        isAwaitingPaymentCompletion,
        isBackwardMotion,
        isCartOrder,
        isLinkPaymentAppAvailable,
      },
      selectedPaymentMode,
      selectedPaymentObject = {},
    } = this.props;

    let { totalAmountFormatted } = this.props;
    const isCODandNonCartPayment =
      selectedPaymentMode === 'cash_on_delivery' && !isCartOrder;
    // Modifying totalAmountFormatted to include convenience fee for cash on delivery
    if (isCODandNonCartPayment) {
      totalAmountFormatted = selectedPaymentObject.totalAmount;
    }
    if (!isOfferAvailable) {
      return <PaymentUnavailable />;
    }

    const isRetryingCartPayment = isRetrying && isCartOrder;

    return (
      <div
        ref={(ref) => {
          this.props.handleAsyncFormState(ref);
        }}
      >
        {isAwaitingPaymentCompletion ? <PaymentCompletionLoader /> : null}
        <div
          className="payments-form"
          style={
            isAwaitingPaymentCompletion
              ? { display: 'none' }
              : isRetryingCartPayment
              ? { border: '0px' }
              : {}
          }
        >
          <Row>
            <Columns
              className={classNames('small-12 hard--sides', {
                'medium-8 large-6 medium-centered': isRetryingCartPayment,
              })}
            >
              <PaymentHeader
                isRetryingCartPayment={isRetryingCartPayment}
                totalAmountFormatted={totalAmountFormatted}
                settings={this.props.settings}
                handleToggleOpenState={this.props.handleToggleOpenState}
                convenienceFeeVersion={this.props.convenienceFeeVersion}
              />

              <PaymentContent
                showInitializePurchase={isInitializeFormOpen}
                showRefinePurchase={isShowRefinePurchaseBox && isRefineFormOpen}
                showFinalizePurchase={isShowFinalizePurchaseBox && isFinalizeFormOpen}
                isBackwardMotion={isBackwardMotion}
              />
            </Columns>
            {isLinkPaymentAppAvailable && isInitializeFormOpen ? (
              <div
                className="text-purple pointer soft--bottom text-center"
                onClick={() => this.props.handleTogglePaymentLinkPopup(true)}
              >
                Back
              </div>
            ) : null}
          </Row>
        </div>
      </div>
    );
  }

  render() {
    const {
      isEmbed,
      isIFrame,
      sellerAvatar,
      seller,
      showDeferred,
      isLinkPayment,
      isPaymentResponse,
      responseType,
      isInstapayPayment,
      isRAP,
      isInstapayInitiated,
      isCartOrder,
      isRetrying,
      shouldShowPaymentOptionsDirectlyOnRetry,
      smartlinksHeader,
      showNeftLogin,
      hasSmartPricing,
      showConvenienceFeeForm,
      isFinalizeFormOpen,
      showReportLink,
    } = this.props.settings;
    const {
      title,
      preview,
      selectedPaymentMode,
      selectedPaymentObject = {},
    } = this.props;
    let { totalAmountFormatted } = this.props;
    const convenienceFeeVersion = this.props.convenienceFeeVersion;
    const isCODandNonCartPayment =
      selectedPaymentMode === 'cash_on_delivery' && !isCartOrder;
    // Modifying totalAmountFormatted to include convenience fee for cash on delivery
    if (isCODandNonCartPayment) {
      totalAmountFormatted = selectedPaymentObject.totalAmount;
    }
    const merchantHeaderProps = {
      showDeferred,
      preview,
      sellerAvatar,
      seller,
      title,
      totalAmountFormatted,
      smartlinksHeader,
      hasSmartPricing,
      showConvenienceFeeForm,
      convenienceFeeVersion,
      isFinalizeFormOpen,
    };

    const isRetryingCartPayment = isRetrying && isCartOrder;

    if (!isPaymentResponse) {
      /*
        isEmbed is True
        Standalone form embedded page
        Eg: Any Link, Product Page, append ?embed=form in the URL
      */
      /*
        isEmbed && isIFrame is True
        Everything powered via checkout.js, Eg. OnlineStore
      */
      if (isEmbed) {
        //Use transparent background For embed and iframe mode
        document.body.style.background = isIFrame ? 'none' : '#F1F4F6';
      }
      return (
        <div>
          {/* Merchant Header */}
          {showNeftLogin ? (
            <LoginNeft />
          ) : (
            <div onKeyDown={isIFrame ? this.handleKeyDown.bind(this) : null}>
              {isEmbed && !isIFrame ? null : isLinkPayment ? (
                <LinksMerchantHeader {...merchantHeaderProps} />
              ) : isInstapayPayment ? (
                <InstapayMerchantHeader {...merchantHeaderProps} isEditing={!isRAP} />
              ) : isRetryingCartPayment ? (
                <div style={{ background: '#fff', borderRadius: '4px 4px 0 0' }}>
                  <CartHeader
                    purchased={false}
                    mode={Modes.FULL}
                    totalItems={getCartTotalItems()}
                  />
                </div>
              ) : (
                <ProductsMerchantHeader {...merchantHeaderProps} />
              )}

              {/* Core Content */}
              {isInstapayPayment && !isRAP && !isInstapayInitiated ? (
                <InstapayForm />
              ) : (
                this._renderPaymentForm()
              )}
              {showReportLink && !inIframe() ? (
                <Center
                  w="full"
                  px={10}
                  py={20}
                  color="#4E4E91"
                  textDecoration="underline"
                  cursor="pointer"
                >
                  <span
                    onClick={() => {
                      root.location.href = `/report-abuse?seller=${seller?.username}&productType=1&productLink=${root.location.href}`;
                    }}
                  >
                    Report issue
                  </span>
                </Center>
              ) : null}
              {/* Footer */}
              <BrandFooter
                settings={this.props.settings}
                type={isEmbed && !isIFrame ? 'dark' : 'light'}
              />
            </div>
          )}
        </div>
      );
    } else {
      return (
        <PostPurchase
          responseType={responseType}
          isCartOrder={isCartOrder}
          shouldShowPaymentOptionsDirectlyOnRetry={
            shouldShowPaymentOptionsDirectlyOnRetry
          }
          postPurchase={this.props.postPurchase}
          settings={this.props.settings}
        />
      );
    }
  }
}

Payments.propTypes = {
  settings: PropTypes.object,
  totalAmountFormatted: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  title: PropTypes.string,
  preview: PropTypes.string,
  postPurchase: PropTypes.object,
  convenienceFeeVersion: PropTypes.string,
  handleAsyncFormState: PropTypes.func,
  handleToggleOpenState: PropTypes.func,
  handleTogglePaymentPopup: PropTypes.func,
  setOfferPagePaymentButtonClickListener: PropTypes.func,
  showDeferredMerchantHeader: PropTypes.func,
  selectedPaymentMode: PropTypes.string,
  selectedPaymentObject: PropTypes.object,
  handleTogglePaymentLinkPopup: PropTypes.func,
  fetchReportAbuseInfo: PropTypes.func,
  fetchTosPoliciesInfo: PropTypes.func,
};

export default connect(getPaymentsState, {
  handleToggleOpenState,
  handleTogglePaymentPopup,
  setOfferPagePaymentButtonClickListener,
  showDeferredMerchantHeader,
  handleTogglePaymentLinkPopup,
  fetchReportAbuseInfo,
  fetchTosPoliciesInfo,
})(paymentWrapper(formFieldsHandler(Payments)));
